<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import HeaderNav from '@/components/header/HeaderNav.ce.vue';
import SideNav from '@/components/header/SideNav.ce.vue';
import User from '@/components/header/User.ce.vue';
import LanguageSwitcher from '@/components/LanguageSwitcher.ce.vue';
import BackToMyMultivac from '@/components/header/BackToMyMultivac.ce.vue';
import { filterNavigationItems, isDemo, mapNavigationItemsToEnriched } from '@/utils/navigation-item.utils';
import { QueryParameters, SharedParameters, QueryParamKey } from '@/components/SharedParameters';
import { parseJwt } from '@/utils/jwt.utils';
import {
  appendSearchParamsToUrl,
  parseCustomerIdFromQueryParams,
  parseDateRangeEndFromQueryParams,
  parseDateRangeStartFromQueryParams,
  parseLanguageCodeFromQueryParams,
  parseMachineNameFromQueryParams, parseReturnUrlFromQueryParams,
  replaceStateInWindowLocation,
} from '@/utils/QueryParamUtils';
import { EnrichedNavigationItem } from '@/components/header/EnrichedNavigationItem';
import { fetchPermittedSmartServices } from '@/utils/fetch.utils';
import { getLogoData } from '@/utils/header.utils';
import { Logo } from '@/components/header/Logo';
import { validateLanguageCode } from '@/i18n/pickerLanguages';

const props = withDefaults(defineProps<{
  authorizationHeader?: string,
  smartServiceId?: string,
  customerName?: string,
  loginUrl?: string,
  logoutUrl?: string,
  loginListener?: boolean,
  logoutListener?: boolean,
  sharedParameters?: string,
  appName?: string,
  logo?: Logo,
  languageCodes?: string,
}>(), {
  sharedParameters: '',
  logo: 'mymultivac',
  languageCodes: '',
});

const emit = defineEmits([
  'shared-parameters-queried',
  'logout-clicked',
  'login-clicked',
  'language-changed',
]);

const returnUrl = ref('https://my-multivac.com/portal');
const customerId = ref('');
const isSideNavShown = ref(false);
const queriedSharedParameters = ref<SharedParameters>({});
const permittedSmartServices = ref<string[]>([]);
const isDemoMode: boolean = isDemo();

const parsedSharedParameters = computed<SharedParameters>(() => {
  if (props.sharedParameters === '') {
    return {};
  }

  try {
    return JSON.parse(props.sharedParameters);
  } catch (e: unknown) {
    const error = !(e instanceof Error) ? new Error(e as string) : e;
    // eslint-disable-next-line no-console
    console.error(`Invalid format of attribute "sharedParameters" [${ props.sharedParameters }]: ` + error.message);
    return {};
  }
});

const computedQueryParameters = computed<QueryParameters>(() => {
  return {
    machineName: computedMachineName.value,
    languageCode: computedLanguageCode.value,
    dateRangeStart: computedDateRangeStart.value,
    dateRangeEnd: computedDateRangeEnd.value,
    returnUrl: computedReturnUrl.value,
    customerId: customerId.value,
  };
});

const computedMachineName = computed(() => {
  return parsedSharedParameters.value.machineName ?? queriedSharedParameters.value.machineName;
});

const computedLanguageCode = computed(() => {
  const sharedLanguageCode = parsedSharedParameters.value.languageCode ?? queriedSharedParameters.value.languageCode;
  return sharedLanguageCode ?? 'en';
});

const computedDateRangeStart = computed(() => {
  return parsedSharedParameters.value.dateRangeStart ?? queriedSharedParameters.value.dateRangeStart;
});

const computedDateRangeEnd = computed(() => {
  return parsedSharedParameters.value.dateRangeEnd ?? queriedSharedParameters.value.dateRangeEnd;
});

const computedReturnUrl = computed(() => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set(QueryParamKey.LANGUAGE_CODE, computedLanguageCode.value);
  return appendSearchParamsToUrl(returnUrl.value, urlSearchParams);
});

const userEmail = computed(() => {
  return props.authorizationHeader
    ? parseJwt(props.authorizationHeader).email
    : undefined;
});

const userName = computed(() => {
  return props.authorizationHeader
    ? parseJwt(props.authorizationHeader).name
    : undefined;
});

const logoData = computed(() => getLogoData(props.logo));

const showSmartServicesLogo = computed(() => !(props.appName || props.logo === 'multivac'));

const filteredNavigationItems = computed<EnrichedNavigationItem[]>(() => {
  const isDev = process.env.VITE_STAGE === 'dev';

  if (props.appName) {
    return [{
      title: props.appName,
      url: window.location.href,
      isActive: true,
    }];
  } else {
    const filteredItems = filterNavigationItems(permittedSmartServices.value, isDev, props.smartServiceId);
    return mapNavigationItemsToEnriched(filteredItems, isDemoMode, props.smartServiceId);
  }
});

function parsedLanguageCodes(): string[] {
  if (props.languageCodes === '') {
    return [];
  }

  try {
    const json = JSON.parse(props.languageCodes);

    if (!Array.isArray(json)) {
      throw new Error('Expected array');
    }

    json.forEach((item?: string) => {
      validateLanguageCode(item);
    });

    return json;
  } catch (e) {
    const error = !(e instanceof Error) ? new Error(e as string) : e;
    throw new Error(`Invalid format of attribute "languages" [${ props.languageCodes }]: ` + error.message);
  }
}

function languageChanged(newLanguage: string): void {
  emit('language-changed', newLanguage);
}

onMounted(async () => {
  emitQueryParameters();
  await updatePermittedSmartServices();
});

watch(() => props.authorizationHeader, async () => {
  await updatePermittedSmartServices();
});
watch(computedQueryParameters, (queryParameters) => replaceStateInWindowLocation(queryParameters), { deep: true });

async function updatePermittedSmartServices() {
  if (!props.authorizationHeader || !userEmail.value) {
    permittedSmartServices.value = [];
    return;
  }

  permittedSmartServices.value = await fetchPermittedSmartServices(props.authorizationHeader);
}

function emitQueryParameters(): void {
  const queriedLanguageCode = parseLanguageCodeFromQueryParams();
  const queriedMachineName = parseMachineNameFromQueryParams();
  const queriedDateRangeStart = parseDateRangeStartFromQueryParams();
  const queriedDateRangeEnd = parseDateRangeEndFromQueryParams();
  const queriedCustomerId = parseCustomerIdFromQueryParams();
  returnUrl.value = parseReturnUrlFromQueryParams();
  if (queriedLanguageCode) {
    queriedSharedParameters.value.languageCode = queriedLanguageCode;
  }
  if (queriedMachineName) {
    queriedSharedParameters.value.machineName = queriedMachineName;
  }
  if (queriedDateRangeStart) {
    queriedSharedParameters.value.dateRangeStart = queriedDateRangeStart;
  }
  if (queriedDateRangeEnd) {
    queriedSharedParameters.value.dateRangeEnd = queriedDateRangeEnd;
  }
  if (queriedCustomerId) {
    customerId.value = queriedCustomerId;
  }
  emit('shared-parameters-queried', queriedSharedParameters.value);
}

</script>

<template>
  <header id="header">
    <div
      id="top-header"
      :inert="isSideNavShown"
    >
      <div id="logo">
        <a
          :href="computedReturnUrl"
          :title="logoData.title"
        >
          <img
            :alt="logoData.title"
            class="logo"
            :src="logoData.src"
          >
        </a>

        <img
          alt="Smart Services"
          class="smart-service-logo"
          src="../assets/img/smart-service-logo.svg?url"
          v-if="showSmartServicesLogo"
        >
      </div>

      <ul class="meta-nav-top">
        <li id="back-to-my-multivac">
          <BackToMyMultivac
            :current-language-code="computedLanguageCode"
            :url="computedReturnUrl"
            v-if="logo === 'mymultivac'"
          />
        </li>
        <li id="language-picker" class="">
          <LanguageSwitcher
            :current-language="computedLanguageCode"
            :languages="parsedLanguageCodes()"
            @language-changed="languageChanged"
          />
        </li>
        <li>
          <User
            v-if="userName || loginUrl || loginListener"
            id="user-menu-or-login"
            :current-language-code="computedLanguageCode"
            :customer-name="customerName"
            :user-email="userEmail"
            :has-logout-button="!!logoutUrl || !!logoutListener"
            :login-url="loginUrl"
            :logout-url="logoutUrl"
            :user-name="userName"
            @login-clicked="$emit('login-clicked')"
            @logout-clicked="$emit('logout-clicked')"
          />
        </li>
      </ul>
    </div>

    <HeaderNav
      :navigation-items="filteredNavigationItems"
      :query-parameters="computedQueryParameters"
      @show-side-nav="isSideNavShown = true"
      @hide-side-nav="isSideNavShown = false"
      :inert="isSideNavShown"
    />

    <SideNav
      :is-shown="isSideNavShown"
      :inert="!isSideNavShown"
      :navigation-items="filteredNavigationItems"
      :query-parameters="computedQueryParameters"
      :logo="logoData"
      :show-smart-services-logo="showSmartServicesLogo"
      @hide="isSideNavShown = false"
    >
      <ul class="meta-nav-side">
        <li>
          <User
            v-if="userName || loginUrl || loginListener"
            :current-language-code="computedLanguageCode"
            :has-logout-button="!!logoutUrl || !!logoutListener"
            :login-url="loginUrl"
            :logout-url="logoutUrl"
            :user-name="userName"
            class="side-nav-user"
            @login-clicked="$emit('login-clicked')"
            @logout-clicked="$emit('logout-clicked')"
          />
        </li>
        <li>
          <LanguageSwitcher
            :current-language="computedLanguageCode"
            :languages="parsedLanguageCodes()"
            @language-changed="languageChanged"
          />
        </li>
        <li>
          <BackToMyMultivac
            :current-language-code="computedLanguageCode"
            :url="computedReturnUrl"
            class="side-nav-back-button"
            v-if="logo === 'mymultivac'"
          />
        </li>
      </ul>

    </SideNav>
  </header>
</template>

<style lang="scss">
@import "../assets/styles/base";
@import "../assets/styles/colors";
@import "../assets/styles/constants";

#header {
  font-family: $font-stack;
  background-color: $white;
  color: $dark-gray;
  margin: 0;
  padding: 1.5rem 1rem 0;

  box-shadow: inset 0 -1px 0 0 $shadow-gray, 0 2px 4px 0 rgba(0, 0, 0, .07);

  @media (min-width: $s4-grid-mobile-breakpoint) {
    padding: 1.5rem 2rem 0;
  }

  #top-header {
    align-items: center;
    display: flex;
    gap: 2rem;
    justify-content: space-between;

    #logo {
      flex: 1;
      display: flex;
      align-items: center;

      a {
        display: flex;
        align-items: center;
      }

      img.logo {
        height: 2rem;
        margin-right: 1rem;
      }

      img.smart-service-logo {
        height: 0.85714286rem;
        padding-left: 1rem;
        border-left: 2px solid $border-gray;
        display: none;

        @media (min-width: $breakpoint-smart-service-logo) {
          display: inline;
        }
      }
    }

    .meta-nav-top {
      display: flex;
      align-items: center;
      gap: 2rem;
      justify-content: flex-end;
    }

    #back-to-my-multivac {
      display: none;
      @media (min-width: $breakpoint-back-to-my-multivac) {
        display: block;
      }
    }

    #language-picker {
      display: none;
      @media (min-width: $breakpoint-language-picker) {
        display: block;
      }
    }

    #user-menu-or-login {
      display: none;

      @media (min-width: $mobile-nav-only-breakpoint) {
        display: block;
      }
    }
  }

  .meta-nav-side {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .side-nav-user .user-customer-label {
      display: block;
    }
  }
}

</style>
