<script lang="ts" setup>
import { getTranslation } from '@/i18n/translations';
import { computed } from 'vue';
import SvgIcon from '../SvgIcon.ce.vue';

const props = withDefaults(defineProps<{
  currentLanguageCode: string,
  url: string
}>(), {
  currentLanguageCode: 'en',
});

const label = computed(() => getTranslation('BACK_TO_MY_MULTIVAC', props.currentLanguageCode));
</script>

<template>
  <a :href="url" class="menu-link with-icon">
    <SvgIcon name="arrow-right" class="icon" />
    <span>{{ label }}</span>
  </a>
</template>

<style lang="scss" scoped>
@import '../../assets/styles/constants';

a {
  white-space: nowrap;
}

.side-nav-back-button {
  padding: .5rem 0;
}
</style>
