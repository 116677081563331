import { QueryParameters, QueryParamKey } from '@/components/SharedParameters';

export function parseLanguageCodeFromQueryParams(): string | null {
  return getQueryParamFromUrl(QueryParamKey.LANGUAGE_CODE);
}

export function parseMachineNameFromQueryParams(): string | null {
  return getQueryParamFromUrl(QueryParamKey.MACHINE_NAME);
}

export function parseDateRangeStartFromQueryParams(): string | null {
  return getQueryParamFromUrl(QueryParamKey.DATE_RANGE_START);
}

export function parseDateRangeEndFromQueryParams(): string | null {
  return getQueryParamFromUrl(QueryParamKey.DATE_RANGE_END);
}

export function parseCustomerIdFromQueryParams(): string | null {
  return getQueryParamFromUrl(QueryParamKey.CUSTOMER_ID);
}

export function parseReturnUrlFromQueryParams(): string {
  const returnUrl = getQueryParamFromUrl('return-url');

  if (returnUrl === null || (returnUrl
    && !returnUrl.startsWith('https://test.my-multivac.com/')
    && !returnUrl.startsWith('https://my-multivac.com/'))) {
    return process.env.VITE_MY_MULTIVAC_URL!;
  }

  return returnUrl;
}

export function createURLSearchParamsObject(queryParameters: QueryParameters): URLSearchParams {
  const urlSearchParams = new URLSearchParams();
  if (queryParameters.languageCode) {
    urlSearchParams.set(QueryParamKey.LANGUAGE_CODE, queryParameters.languageCode);
  }
  if (queryParameters.machineName) {
    urlSearchParams.set(QueryParamKey.MACHINE_NAME, queryParameters.machineName);
  }
  if (queryParameters.dateRangeStart) {
    urlSearchParams.set(QueryParamKey.DATE_RANGE_START, queryParameters.dateRangeStart);
  }
  if (queryParameters.dateRangeEnd) {
    urlSearchParams.set(QueryParamKey.DATE_RANGE_END, queryParameters.dateRangeEnd);
  }
  if (queryParameters.customerId) {
    urlSearchParams.set(QueryParamKey.CUSTOMER_ID, queryParameters.customerId);
  }
  urlSearchParams.set(QueryParamKey.RETURN_URL, queryParameters.returnUrl);
  return urlSearchParams;
}

export function replaceStateInWindowLocation(queryParams: QueryParameters): void {
  if (queryParams.languageCode) {
    updateQueryParamInUrl(QueryParamKey.LANGUAGE_CODE, queryParams.languageCode);
  }
  if (queryParams.machineName) {
    updateQueryParamInUrl(QueryParamKey.MACHINE_NAME, queryParams.machineName);
  }
  if (queryParams.dateRangeStart) {
    updateQueryParamInUrl(QueryParamKey.DATE_RANGE_START, queryParams.dateRangeStart);
  }
  if (queryParams.dateRangeEnd) {
    updateQueryParamInUrl(QueryParamKey.DATE_RANGE_END, queryParams.dateRangeEnd);
  }
  if (queryParams.customerId) {
    updateQueryParamInUrl(QueryParamKey.CUSTOMER_ID, queryParams.customerId);
  }
  updateQueryParamInUrl(QueryParamKey.RETURN_URL, queryParams.returnUrl);
}

export function appendSearchParamsToUrl(url: string, urlSearchParams: URLSearchParams): string {
  const urlObject = new URL(url);
  const searchParams = new URLSearchParams(urlObject.search);
  urlSearchParams.forEach((value: string, key: string) => {
    searchParams.set(key, value);
  });

  return appendTrackingIdIfNeeded(urlObject.href.split('?')[0] + '?' + searchParams.toString());
}

/**
 * According to dominik.rotter@multivac.de (2.2.22) it is nice to haven some special
 * parameter to track users navigation via the SWC Header
 */
function appendTrackingIdIfNeeded(url: string) {
  const trackingId = '&utm_medium=HeaderNavigation';
  return url.includes('utm_medium') ? url : url + trackingId;
}

function updateQueryParamInUrl(key: string, value: string): void {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
  history.replaceState(history.state, '', newRelativePathQuery);
}

function getQueryParamFromUrl(key: string): string | null {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
}

