import * as cs from './languages/cs.json';
import * as da from './languages/da.json';
import * as de from './languages/de.json';
import * as en from './languages/en.json';
import * as es from './languages/es.json';
import * as fr from './languages/fr.json';
import * as hu from './languages/hu.json';
import * as it from './languages/it.json';
import * as nl from './languages/nl.json';
import * as no from './languages/no.json';
import * as pl from './languages/pl.json';
import * as pt from './languages/pt.json';
import * as sv from './languages/sv.json';
import * as tr from './languages/tr.json';
import * as zh from './languages/zh.json';
import { SwcPickerLanguagesMap } from './pickerLanguages';

type Translation = {
  default: {
    [key: string]: string;
  };
};

type TranslationsMap = {
  [code in keyof SwcPickerLanguagesMap]: Translation;
};

const defaultLanguage = 'en';

/***
 * Not all languages that are supported in the language picker have translations for the header/footer
 * These are the supported translations for header/footer.
 */
const availableSwcTranslations: TranslationsMap =
  {
    cs,
    da,
    de,
    en,
    es,
    fr,
    hu,
    it,
    nl,
    no,
    pl,
    pt,
    sv,
    tr,
    zh,
    'zh-CN': zh,
  } as unknown as TranslationsMap;

export function getTranslation(key: string, lang: string): string {
  if (lang in availableSwcTranslations) {
    return (availableSwcTranslations[lang as keyof TranslationsMap]).default[key] || key;
  }

  return availableSwcTranslations[defaultLanguage].default[key] || key;
}

