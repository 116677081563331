export const swcPickerLanguages = {
  'cs': 'Čeština',
  'da': 'Dansk',
  'de': 'Deutsch',
  'en': 'English',
  'es': 'Español',
  'fr': 'Français',
  'hu': 'Magyar',
  'it': 'Italiano',
  'ja': '日本語', //uses english in header/footer
  'nl': 'Nederlands',
  'no': 'Norsk',
  'pl': 'Polski',
  'pt': 'Português',
  'ru': 'Русский', //uses english in header/footer
  'sv': 'Svenska',
  'tr': 'Türkçe',
  'fi': 'Suomi', //uses english in header/footer,
  'zh': '简体中文', // Chinese fallback
  'zh-CN': '简体中文', // Chinese simplified
  'zh-HK': '中国传统的', // Chinese traditional

};

export type SwcPickerLanguagesMap = typeof swcPickerLanguages;

export function sortLanguagesByTranslatedLabel(a: string, b: string): number {
  return swcPickerLanguages[a as keyof SwcPickerLanguagesMap]
    .localeCompare(swcPickerLanguages[b as keyof SwcPickerLanguagesMap]);
}

export const swcPickerLanguageCodes = Object.keys(swcPickerLanguages).sort(sortLanguagesByTranslatedLabel);

export function preventDuplicateEntries(languageCode: string): boolean {
  return !['zh'].includes(languageCode);
}

export function validateLanguageCode(languageCode?: string):void {
  if (typeof languageCode !== 'string' || languageCode.length > 5 || languageCode.length < 2) {
    throw new Error(`Invalid language code [${ languageCode }]`);
  }
}
