<script lang="ts" setup>
import { computed, watch } from 'vue';
import { getTranslation } from '@/i18n/translations';
import { createDataOutageUrl } from '@/utils/footer.utils';
import { validateLanguageCode } from '@/i18n/pickerLanguages';

const props = withDefaults(defineProps<{
  currentLanguageCode?: string,
  machineName?: string,
}>(), {
  currentLanguageCode: 'en',
});

const dataPrivacyLabel = computed(() => getTranslation('DATA_PRIVACY', props.currentLanguageCode));

const imprintLabel = computed(() => getTranslation('IMPRINT', props.currentLanguageCode));

const contactLabel = computed(() => getTranslation('CONTACT', props.currentLanguageCode));

const downloadsLabel = computed(() => getTranslation('DOWNLOADS', props.currentLanguageCode));

const dataOutageLabel = computed(() => getTranslation('FIX_DATA_OUTAGE', props.currentLanguageCode));

watch(() => props.currentLanguageCode, (newLanguageCode: string) => {
  try {
    validateLanguageCode(newLanguageCode);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}, { immediate: true });
</script>

<template>
  <footer id="footer">
    <div id="branding">
      <div class="top-footer-group">
        <a href="https://my-multivac.com/">
          <img
            alt="MyMULTIVAC"
            src="../assets/img/mymultivac-bright.svg?url"
            title="MyMULTIVAC"
          >
        </a>
        © Copyright MULTIVAC
      </div>
    </div>
    <div class="footer-links">
      <a href="https://my-multivac.com/data-privacy">{{ dataPrivacyLabel }}</a>
      <a href="https://my-multivac.com/imprint">{{ imprintLabel }}</a>
      <a href="https://my-multivac.com/contact">{{ contactLabel }}</a>
      <a href="https://my-multivac.com/downloads">{{ downloadsLabel }}</a>
      <a :href="createDataOutageUrl(props.machineName)">{{ dataOutageLabel }}</a>
    </div>
  </footer>
</template>

<style lang="scss">
@import "../assets/styles/base";
@import "../assets/styles/colors";

#footer {
  color: $white;
  padding: 2rem 2rem;
  background-color: $primary-dark;
  font-family: $font-stack;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;
  flex-wrap: wrap;

  @media (min-width: $mobile-footer-breakpoint) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  #branding {
    .top-footer-group {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      font-size: .75rem;
      white-space: nowrap;
      gap: 1rem;
      flex-wrap: wrap;

      a, img {
        height: 1.5rem
      }
    }
  }

  .footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;
    align-self: flex-start;

    @media (min-width: $mobile-footer-breakpoint) {
      flex-direction: row;
      gap: 2rem;
    }

    a {
      color: $white;
      font-weight: 400;

      &:hover {
        text-decoration: underline;
        color: $hover-blue;
      }
    }
  }

}
</style>
