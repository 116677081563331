import { defineCustomElement } from 'vue';

import Header from './web-components/Header.ce.vue';
import BackToMyMultivac from '@/components/header/BackToMyMultivac.ce.vue';
import HeaderNav from '@/components/header/HeaderNav.ce.vue';
import SideNav from '@/components/header/SideNav.ce.vue';
import User from '@/components/header/User.ce.vue';
import Footer from './web-components/Footer.ce.vue';
import Backdrop from './components/Backdrop.ce.vue';
import LanguageSwitcher from '@/components/LanguageSwitcher.ce.vue';
import SvgIcon from '@/components/SvgIcon.ce.vue';
import { getFontUrl } from '@/utils/getFontUrl';
import { addFontToApplication } from '@multivac-se/muwo-frontend-shared';

//https://github.com/vuejs/core/issues/4662
Header.styles = [...Header.styles, ...BackToMyMultivac.styles, ...HeaderNav.styles, ...SideNav.styles, ...User.styles, ...Backdrop.styles, ...LanguageSwitcher.styles, ...SvgIcon.styles];
Footer.styles = [...Footer.styles, ...Backdrop.styles];

customElements.define('swc-header', defineCustomElement(Header));
customElements.define('swc-footer', defineCustomElement(Footer));

addFontToApplication(getFontUrl());
