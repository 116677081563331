import navigationItemsProd from '@/web-components/navigation-items/prod.json';
import navigationItemsDev from '@/web-components/navigation-items/dev.json';
import { NavigationItemSmartServices } from '@/components/header/NavigationItem';
import { EnrichedNavigationItem } from '@/components/header/EnrichedNavigationItem';

export function isDemo(): boolean {
  const url = window.location.href;
  return /[^=]demo/.test(url);
}

export function getDemoUrlForApp(navigationItem: NavigationItemSmartServices): string {
  return navigationItem.smartServiceId === 'PP'
    ? 'https://packpilot-demo.multivac.com'
    : navigationItem.url + '/demo';
}

export function filterNavigationItems(permittedSmartServiceIds: string[], isDev: boolean, activeAppId?: string): NavigationItemSmartServices[] {
  const isDemoMode = isDemo();
  const availableNavigationItems = getNavigationItemsForStage(isDev, isDemoMode);
  if (permittedSmartServiceIds.length === 0) {
    return availableNavigationItems.filter(item => item.smartServiceId === activeAppId);
  }

  if (isDemoMode) {
    return availableNavigationItems;
  }

  return availableNavigationItems.filter(item => permittedSmartServiceIds.includes(item.smartServiceId));
}

function getNavigationItemsForStage(isDev: boolean, isDemoMode: boolean): NavigationItemSmartServices[] {
  const items = isDev ? navigationItemsDev : navigationItemsProd;
  return isDemoMode
    ? items.map((item: NavigationItemSmartServices) => ({ ...item, url: getDemoUrlForApp(item) }))
    : items;
}

function getActiveUrlForApp(navigationItem: NavigationItemSmartServices, isDemoMode: boolean): string {
  const activeItem = { ...navigationItem, url: window.location.origin };
  return isDemoMode
    ? getDemoUrlForApp(activeItem)
    : activeItem.url;
}

export function mapNavigationItemsToEnriched(items: NavigationItemSmartServices[], isDemoMode: boolean, activeAppId?: string): EnrichedNavigationItem[] {
  return items.map(item => {
    const isActive = item.smartServiceId === activeAppId;
    return {
      ...item,
      url: isActive ? getActiveUrlForApp(item, isDemoMode) : item.url,
      isActive,
    };
  });
}
