interface RoleDTO {
  code: string;
  smartServiceId: string;
}

export async function fetchPermittedSmartServices(authorizationHeader: string): Promise<string[]> {
  const requestUrl = `${ process.env.VITE_META_DATA_URL }/c2m/users/roles`;
  const requestOptions = { headers: { Authorization: authorizationHeader } };
  try {
    const response = await fetch(requestUrl, requestOptions);
    const res: RoleDTO[] = response.status === 200 ? await response.json() : [];
    return res.map((role) => role.smartServiceId);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Failed to fetch permitted smart services [${ e }]`);
    return [];
  }
}
