<script lang="ts" setup>
import Backdrop from '@/components/Backdrop.ce.vue';
import { getTranslation } from '@/i18n/translations';
import { EnrichedNavigationItem } from '@/components/header/EnrichedNavigationItem';
import { QueryParameters } from '@/components/SharedParameters';
import { computed } from 'vue';
import ProductLink from '@/components/header/ProductLink.vue';
import { LogoData } from './Logo';
import SvgIcon from '@/components/SvgIcon.ce.vue';
const props = defineProps<{
  isShown: boolean,
  navigationItems: EnrichedNavigationItem[],
  queryParameters: QueryParameters,
  logo: LogoData,
  showSmartServicesLogo: boolean,
}>();

const closeMenuLabel = computed(() => getTranslation('CLOSE_MENU', props.queryParameters.languageCode || 'en'));

</script>

<template>
  <div>
    <div
      id="side-nav"
      :class="{ show: isShown }"
    >
      <a href="/">
        <img
          :alt="logo.title"
          class="logo"
          :src="logo.src"
          :title="logo.title"
        >
      </a>

      <div class="smart-service-logo-container">
        <img
          src="../../assets/img/smart-service-logo.svg?url"
          alt="Smart Services"
          class="smart-service-logo"
          v-if="showSmartServicesLogo"
        >
      </div>

      <button
        id="close-side-nav-button"
        @click="$emit('hide')"
        :title="closeMenuLabel"
        class="menu-link"
      >
        <SvgIcon name="arrow-left" class="icon" />
        <span>Menu</span>
      </button>
      <nav>
        <ul>
          <li
            v-for="(item, index) in navigationItems"
            :key="index"
            :class="{ active: item.isActive }"
            @keydown.esc="$emit('hide')"
          >
            <ProductLink
              :title="item.title"
              :query-parameters="queryParameters"
              :is-active="item.isActive"
              :url="item.url"
            />
          </li>
        </ul>
      </nav>
      <div
        class="slot-wrapper"
        @keydown.esc.stop="$emit('hide')"
      >
        <slot />
      </div>
    </div>
    <Backdrop
      :has-shadow="true"
      :is-visible="isShown"
      @hide="$emit('hide')"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/styles/base";

$side-nav-width: 15.75rem;
$side-nav-padding: 1.25rem;

#side-nav {
  background-color: $white;
  height: 100%;
  width: $side-nav-width;
  position: fixed;
  z-index: $z-index-sidenav;
  top: 0;
  left: 0 - $side-nav-width - 2 * $side-nav-padding;
  padding: $side-nav-padding;
  transition: 0.3s;

  &.show {
    left: 0;
    box-shadow: 0.3125rem 0 3rem 0 rgba(0, 0, 0, .5);
  }

  .icon {
    height: $icon-size;
    width: $icon-size;
    margin-right: $icon-margin;
  }

  .logo {
    width: 11.125rem;
  }

  .smart-service-logo-container {
    margin-top: 1rem;

    .smart-service-logo {
      width: 8rem;
    }
  }

  ul {
    list-style: none;
    padding:.25rem 0;
    margin: 0;
    border-bottom: 1px solid $border-gray;
    border-top: 1px solid $border-gray;

    li {
      a {
        display: inline-block;
        width: 100%;
        padding: .5rem 0;
      }
    }
  }

  #close-side-nav-button {
    cursor: pointer;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
  }

  .display-flex {
    display: flex;
  }
}
</style>
