<script lang="ts" setup>
import { getTranslation } from '@/i18n/translations';
import Backdrop from '@/components/Backdrop.ce.vue';
import { computed, ref } from 'vue';
import SvgIcon from '@/components/SvgIcon.ce.vue';

const props = defineProps<{
  currentLanguageCode: string,
  hasLogoutButton: boolean,
  userName?: string
  userEmail?: string
  customerName?: string
  loginUrl?: string
  logoutUrl?: string
}>();

const emit = defineEmits([
  'login-clicked',
  'logout-clicked',
]);

const isDropdownVisible = ref(false);

const myProfileLabel = computed(() => getTranslation('MY_PROFILE', props.currentLanguageCode));

const helpAndSupportLabel = computed(() => getTranslation('HELP_AND_SUPPORT', props.currentLanguageCode));

const userLabel = computed(() => getTranslation('USER', props.currentLanguageCode));

const loginLabel = computed(() => getTranslation('LOGIN', props.currentLanguageCode));

const logoutLabel = computed(() => getTranslation('LOGOUT', props.currentLanguageCode));

function loginOrProfileClickHandler(): void {
  if (props.userName) {
    isDropdownVisible.value = !isDropdownVisible.value;
  } else if (props.loginUrl) {
    window.location.href = props.loginUrl;
  } else {
    emit('login-clicked');
  }
}

function logoutClickHandler(): void {
  if (props.logoutUrl) {
    window.location.href = props.logoutUrl;
  } else {
    emit('logout-clicked');
  }
}

const tooltipContent = computed(() => {
  if (props.userName) {
    return `${ props.userName } ${ props.userEmail ?? '' } ${ props.customerName ?? '' }`;
  }
  return loginLabel.value;
});
</script>

<template>
  <div>
    <div
      class="user-container"
      :class="{'show-dropdown': isDropdownVisible}"
    >
      <button
        @click="loginOrProfileClickHandler"
        @keydown.esc="isDropdownVisible = false"
        class="user menu-link with-icon"
        :title="tooltipContent"
        aria-haspopup="listbox"
      >
        <SvgIcon name="user-icon" class="icon" />

        <span v-if="userName" class="user-customer-label">
          <span class="user-label">{{ userName }}</span>
          <span v-if="userEmail" class="user-email">{{ userEmail }}</span>
          <span v-if="customerName" class="customer-label">
            {{ customerName }}
          </span>
        </span>
        <span v-else>{{ loginLabel }}</span>
      </button>
      <div
        v-if="isDropdownVisible"
        class="user-menu dropdown dropdown-right anchor-top"
        @keydown.esc.stop="isDropdownVisible = false"
        role="listbox"
      >
        <ul>
          <li>
            <a href="https://my-multivac.com/my-account/update-profile">{{ myProfileLabel }}</a>
          </li>
          <li>
            <a href="https://my-multivac.com/help">{{ helpAndSupportLabel }}</a>
          </li>
          <li v-if="hasLogoutButton">
            <a
              class="logout"
              href="javascript:"
              @click="logoutClickHandler"
            >{{ logoutLabel }}</a>
          </li>
        </ul>
      </div>
    </div>
    <Backdrop
      :is-visible="isDropdownVisible"
      @hide="isDropdownVisible=false"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/styles/base";
@import "../../assets/styles/dropdown";

.user-container {
  position: relative;

  .user {
    appearance: none;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;

    .user-customer-label {
      display: none;
      text-align: left;
      overflow: hidden;

      @media (min-width: $breakpoint-user-name) {
        display: block;
      }

      .customer-label {
        display: block;
        max-width: 12.5rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .user-email {
      display: none
    }
  }

  &.show-dropdown:before {
    top: 3rem - $dropdown-arrow-border-offset;
    right: $dropdown-arrow-offset-x;
  }

  .user-menu {
    top: 3rem;
    min-width: 12.5rem;
    z-index: $z-index-context-menu;
  }
}

.side-nav-user .user-container {
  padding: .5rem 0;

  &.show-dropdown:before {
    top: 3rem - $dropdown-arrow-border-offset;
    left: 2rem;
  }

  .user-menu {
    top: 3rem;
    left: 0;
    right: auto;
  }

  .dropdown {
    ul:before {
      left: calc(2rem + #{$dropdown-border-width});
      right: auto;
    }
  }
}
</style>
