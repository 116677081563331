<script lang="ts" setup>
import { getTranslation } from '@/i18n/translations';
import { EnrichedNavigationItem } from '@/components/header/EnrichedNavigationItem';
import { QueryParameters } from '@/components/SharedParameters';
import { computed } from 'vue';
import ProductLink from '@/components/header/ProductLink.vue';
import SvgIcon from '@/components/SvgIcon.ce.vue';

const props = defineProps<{
  navigationItems: EnrichedNavigationItem[],
  queryParameters: QueryParameters
}>();

const menuLabel = computed(() => getTranslation('MENU', props.queryParameters.languageCode || 'en'));

</script>

<template>
  <div id="header-nav">
    <button
      id="side-menu-toggle-button"
      :title="menuLabel"
      @click="$emit('show-side-nav')"
      @keydown.esc="$emit('hide-side-nav')"
      class="menu-link"
    >
      <SvgIcon name="menu-icon" class="icon" />
    </button>
    <ul>
      <li
        v-for="(item, index) in navigationItems"
        :key="index"
        :class="{active: item.isActive}"
      >
        <ProductLink
          :title="item.title"
          :query-parameters="queryParameters"
          :is-active="item.isActive"
          :url="item.url" />
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/styles/base";
@import "../../assets/styles/colors";
@import "../../assets/styles/constants";
@import "../../assets/styles/responsiveness";

#header-nav {
  margin-top: 1.5rem;
  height: 3rem;
  display: flex;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      position: relative;
      margin-right: 2.5rem;
      line-height: 2.1rem;

      a {
        display: block;
        line-height: 1.875rem;

        @media (min-width: $mobile-nav-breakpoint) {
          padding: 0;
        }

      }

      &:not(.active) {
        @extend .hide-on-mobile;
      }

      &.active:after {
        $border-radius: 0.1875rem;
        position: absolute;
        content: "";
        left: -.25rem;
        right: -.25rem;
        bottom: -1.125rem;
        height: .375rem;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        background-color: $active-product-color;
      }
    }
  }
}

#side-menu-toggle-button {
  margin-right: 2rem;
  height: 2.3rem;
  @extend .hide-on-wide-screens;
}
</style>
