export interface SharedParameters {
  languageCode?: string;
  machineName?: string;
  dateRangeStart?: string;
  dateRangeEnd?: string;
}

export interface QueryParameters extends SharedParameters {
  returnUrl: string;
  customerId?: string;
}

export enum QueryParamKey {
  LANGUAGE_CODE = 'locale',
  MACHINE_NAME = 'machine-serial-no',
  DATE_RANGE_START = 'date-range-start',
  DATE_RANGE_END = 'date-range-end',
  RETURN_URL = 'return-url',
  CUSTOMER_ID = 'customer-id'
}
