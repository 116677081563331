import jwt_decode from 'jwt-decode';

export function parseJwt(jwt: string): User {
  const jwtPayload = jwt.split('.')[1];

  if (!jwtPayload) {
    throw new Error('Payload must not be undefined');
  }

  const data: TokenPayload = jwt_decode(jwt);

  if (!data.name) {
    throw new Error(`No name is provided in jwt payload [${ JSON.stringify(data) }]`);
  }

  if (!Array.isArray(data.emails) || !data.emails.length) {
    throw new Error('Invalid emails provided');
  }

  return {
    name: data.name,
    email: data.emails[0],
  };

}

type TokenPayload = {
  name?: string,
  emails?: string[],
};

type User = {
  name: string,
  email: string,
};
