<script lang="ts" setup>
import { getTranslation } from '../i18n/translations';
import Backdrop from './Backdrop.ce.vue';
import {
  preventDuplicateEntries,
  sortLanguagesByTranslatedLabel,
  swcPickerLanguageCodes,
  swcPickerLanguages,
  SwcPickerLanguagesMap,
} from '../i18n/pickerLanguages';
import { computed, ref } from 'vue';
import SvgIcon from './SvgIcon.ce.vue';

const props = defineProps<{
  currentLanguage: string,
  languages: string[],
}>();

const emit = defineEmits(['language-changed']);

const isDropdownVisible = ref(false);
const supportedLanguages = swcPickerLanguages;

const changeableLanguages = computed(() => {
  const supportedLanguageCodes = Object.keys(supportedLanguages);
  return props.languages
    .filter((languageCode: string) => supportedLanguageCodes.includes(languageCode))
    .filter((languageCode: string) => languageCode !== props.currentLanguage)
    .filter(preventDuplicateEntries)
    .sort(sortLanguagesByTranslatedLabel);
});

const selectLanguageLabel = computed(() => getTranslation('SELECT_LANGUAGE', props.currentLanguage));

function changeLanguage(newLanguage: string): void {
  isDropdownVisible.value = false;
  emit('language-changed', newLanguage);
}

function getLanguageLabel(languageCode: string): string {
  if (!swcPickerLanguageCodes.includes(languageCode)) {
    // eslint-disable-next-line no-console
    console.warn(`LanguageCode [${ languageCode }] is not supported, falls back to [en]`);
    languageCode = 'en';
  }
  return supportedLanguages[languageCode as keyof SwcPickerLanguagesMap];
}

const dropdownContentRef = ref<HTMLElement | null>(null);
const dropdownContentMaxHeight = computed(() => {
  if (!isDropdownVisible.value) {
    return 'none';
  }
  const y = dropdownContentRef.value?.getBoundingClientRect().y ?? 0;
  const maxHeight = window.innerHeight - y - 10;
  return maxHeight + 'px';
});

</script>

<template>
  <div>
    <div
      class="language-selector-container"
      :class="{'show-dropdown': isDropdownVisible}"
      @keydown.esc="isDropdownVisible=false"
    >

      <span
        v-if="changeableLanguages.length === 0"
        class="current-language menu-link with-icon"
      >
        <SvgIcon name="languages" alt="Languages"/>
        {{ getLanguageLabel(currentLanguage) }}
      </span>

      <button
        v-else
        class="current-language menu-link with-icon"
        @click="isDropdownVisible=!isDropdownVisible"
        aria-haspopup="listbox"
      >
        <SvgIcon name="languages" alt="Languages" class="icon"/>
        <span class="chevron-label">
          {{ getLanguageLabel(currentLanguage) }}
          <span class="icon">
            <SvgIcon
              v-if="isDropdownVisible"
              :alt="selectLanguageLabel"
              :title="selectLanguageLabel"
              class="icon"
              name="chevron-up"
            />
            <SvgIcon
              v-if="!isDropdownVisible"
              :alt="selectLanguageLabel"
              :title="selectLanguageLabel"
              class="icon"
              name="chevron-down"
            />
          </span>
        </span>
      </button>
      <div
        v-if="isDropdownVisible"
        @keydown.esc.stop="isDropdownVisible = false"
        class="language-menu dropdown anchor-top"
        ref="dropdownContentRef"
        role="listbox"
      >
        <ul
          :style="{ maxHeight: dropdownContentMaxHeight }"
        >
          <li
            v-for="(languageCode, index) in changeableLanguages"
            :key="index"
            class="language-switch"
            role="option"
          >
            <button
              @click="changeLanguage(languageCode)"
            >
              {{ getLanguageLabel(languageCode) }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <Backdrop
      :is-visible="isDropdownVisible"
      @hide="isDropdownVisible=false"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/styles/base";
@import "../assets/styles/colors";
@import "../assets/styles/constants";
@import "../assets/styles/dropdown";

.language-selector-container {
  display: flex;
  align-items: center;
  position: relative;

  button.current-language {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .language-menu {
    top: 2rem;
    z-index: $z-index-context-menu;

    ul {
      overflow-y: auto;
    }
  }

  &.show-dropdown {
    &:before {
      top: 2rem - $dropdown-arrow-border-offset;
      right: calc(50% - .5rem);
    }
  }

  .chevron-label {
    display: flex;
    align-items: center;
    gap: 0.125rem;
  }

}
</style>
